@value colors: "../utils/colors.css";
@value red from colors;

.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.body {
    text-align: start;
    margin: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
}

.bodyCenter {
    text-align: start;
    margin: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.error {
    color: red;
    text-align: center;
}

h1 {
    font-size: 2em;
}