@value colors: "../../../utils/colors.css";
@value green from colors;
@value red from colors;

.valid {
    color: green;
}

.invalid {
    color: red;
}