@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 400;
  font-style: normal;
  src: url('GT-Walsheim-Pro-Regular.otf');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 700;
  font-style: normal;
  src: url('GT-Walsheim-Pro-Bold.otf');
}
@font-face {
  font-family: 'GT-Walsheim';
  font-weight: 500;
  font-style: normal;
  src: url('GT-Walsheim-Pro-Medium.otf');
}
@font-face {
  font-family: 'sourcesanspro';
  font-style: normal;
  src: url('https://fonts.gstatic.com/s/sourcesanspro/v13/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNa7lqDY.woff2');
}
@font-face {
  font-family: 'RussoOne';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Russo One'), local('RussoOne-Regular'),
    url(https://fonts.gstatic.com/s/russoone/v8/Z9XUDmZRWg6M1LvRYsHOz8mJ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
