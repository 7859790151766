.appStoreBadge {
    width: 14vw;
  }
  
  .playStoreBadge {
    width: 16vw;
  }
  
  
@media screen and (max-width: 640px) {
    .appStoreBadge {
      width: 30vw;
    }
    
    .playStoreBadge {
      width: 33vw;
    }
}