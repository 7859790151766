.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.listItem {
    cursor: pointer;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.centerCircle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.circle {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spacer {
    height: 2px;
    width: 100%;
    background-color: #ECEBE9;
}

.invisibleSpacer {
    height: 2px;
    width: 100%;
    background-color: #FFFFFF;
}

.enabledCircle {
    background-color: #D46346;
}

.disabledCircle {
    background-color: #ECEBE9;
}

.disabledLabel {
    color: #ECEBE9;
}

.labelsContainer {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    justify-content: center;
}

.labelsContainer label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
}
