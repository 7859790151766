@value colors: "../utils/colors.css";
@value red2 from colors;
@value slate from colors;

.signUp {
    color: red2;
}
.pinFieldContainer {
    display: flex;
    flex-direction: row;
  }

.pinField {
    font: 400 1.0em 'GT-Walsheim';
    margin: 0px 4px;
    border-right: none;
    outline: none;
    text-align: center;
    transition-duration: 250ms;
    transition-property: color, border, box-shadow, transform;
  }