.root {
  display: flex;
  flex-direction: column;
}

.content {
  margin-left: 5vw;
  margin-right: 5vw;
}

.title {
  font-weight: 500;
  font-size: 2.0em;
  text-align: center;
}

.question {
  font-size: 1em;
  text-align: center;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}