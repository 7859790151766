@value colors: "./colors.css";
@value red from colors;

.fontH1 {
  font-family: 'GT-Walsheim';
  font-weight: 500;
  font-size: 60px;
  line-height: 60px;
}

.fontH2 {
  font-family: 'GT-Walsheim';
  font-weight: 400;
  font-size: 55px;
}

.fontH3 {
  font-family: 'GT-Walsheim';
  font-weight: 500;
  font-size: 40px;
}

.fontH4 {
  font-family: 'GT-Walsheim';
  font-weight: 500;
  font-size: 24px;
}

.fontB1 {
  font-family: 'GT-Walsheim';
  font-weight: 500;
  font-size: 32px;
  line-height: 44px;
}

.fontB2 {
  font-family: 'GT-Walsheim';
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
}

.fontB3 {
  font-family: 'GT-Walsheim';
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.input {
  font: 400 1.0em 'GT-Walsheim';
  width: 100%;
  left: 0;
  bottom: 0;
  color: #32325d;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
  border-bottom: 1px solid #2B2B2E;
}

.input::placeholder {
  color: #cfd7df;
}

select {
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: -2px;
  font: 400 1.0em 'GT-Walsheim';
  width: 100%;
  left: 0;
  bottom: 0;
  color: #32325d;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
  border-bottom: 1px solid #2B2B2E;
  line-height: 1.6rem;
}

.errorMsg {
    color: red;
}

@media screen and (max-width: 810px) {
  .fontH1 {
    font-size: 28px;
    line-height: 28px;
  }

  .fontH2 {
    font-family: 'GT-Walsheim';
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

  .fontH3 {
    font-family: 'GT-Walsheim';
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

  .fontH4 {
    font-family: 'GT-Walsheim';
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

  .fontB1 {
    font-family: 'GT-Walsheim';
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }

  .fontB2 {
    font-family: 'GT-Walsheim';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .fontB3 {
    font-family: 'GT-Walsheim';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}
