@value colors: "../../../utils/colors.css";
@value slate from colors;
@value grey from colors;

.button {
    position: relative;
    display: block;
    height: 40px;
    padding: 0 40px;
    background-color: slate;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
    font-size: 1.0rem;
}

.button:disabled, .button[disabled] {
    background-color: #888581;
    cursor: not-allowed;
}

.secondary {
    background-color: transparent;
    color: slate;
}

.secondary:disabled, .secondary[disabled] {
    background-color: transparent;
    color: grey;
    cursor: not-allowed;
}

.loading {
    pointer-events: none;
    cursor: not-allowed;
    color: black;
}

.loader {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 14px);
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid black;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
