.field {
  font: 400 18px 'GT-Walsheim';
  height: 30px;
  margin: 0 10px 0 0;
}

.input {
  /*position: absolute;*/
  font: 400 18px 'GT-Walsheim';
  width: 100%;
  left: 0;
  bottom: 0;
  color: #32325d;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
  border-bottom: 1px solid #2B2B2E;
}

.input::placeholder {
  color: #cfd7df;
}  