.field .container .input {
    font: 400 18px 'GT-Walsheim';
    width: 100%;
    left: 0;
    bottom: 0;
    color: #32325d;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
    height: 22px;
    border-bottom: 1px solid #2B2B2E;
}

.field .container .dropdownButton {
    border: none;
}

.field .container .dropdownButton:hover>div {
    background-color: transparent;
}

.field {
    font: 400 18px 'GT-Walsheim Regular';
    height: 50px;
    margin: 0 10px 0 0;
}

.inputLabel {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: #888581;
}

.inputLabelWithError {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    color: #C23520;
}

.baseLine {
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #cfd7df;
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.baseLineWithError {
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #C23520;
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}
