.border {
    padding-bottom: 5px;
    border-bottom: 1px solid #ECEBE9;
}

.activeBorder {
    padding-bottom: 5px;
    border-bottom: 2px solid #E7C8C3;
}

.error {
    color: red;
    text-align: center;
}

.bodyCenter {
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}