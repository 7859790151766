.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header {
    display: flex;
    flex-direction: row;
    padding: 48px;
}

.spacer {
    flex-grow: 1;
}

.logo {
}

.logo img {
    height: 40px;
}

.cart {
    padding-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cartLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    text-decoration: none;
}

.cartLink:hover,.cartLink:visited {
    color: black;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
