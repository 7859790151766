@value colors: "../../../utils/colors.css";
@value red from colors;
@value red2 from colors;

.invalid {
    color: red;
}

.terms {
    color: red2;
}